<template>


  <el-row class="page">
    <el-col :span="24" style="height: 42px; background: #2D3142;" :sm="0">
      <div class="header-title">订单详情</div>
    </el-col>
    <member></member>
    <el-col :span="24" :sm="0" class="mobile-instance">
      <el-col :span="5" class="mobile-product_img">
        <img :src="instanceInfo.saasProductLogo" style="width: 100%;height: 100%">
      </el-col>
      <el-col class="mobile-product-name" :span="24">{{ instanceInfo.saasProductName }}</el-col>
      <el-col class="mobile-group-but" v-if="orderStatus !== 1" :span="16">
        <el-button class="mobile-btn" @click="goPage('/goodsRenewal', instanceInfo.instanceId, false)">续费</el-button>
        <el-button class="mobile-btn" @click="goPage('/goodsUpgrade', instanceInfo.instanceId, true)">扩容</el-button>
        <el-button class="mobile-btn" @click="goPage('/goodsUpgrade', instanceInfo.instanceId, false)">升级</el-button>
      </el-col>
    </el-col>
    <el-col :span="24" :sm="0" class="mobile-instance">
      <el-col :span="24">
        <el-col :span="6" class="mobile-product-tile">产品ID</el-col>
        <el-col :span="15" class="mobile-product-value">{{ instanceInfo.saasGoodsId }}</el-col>
      </el-col>
      <el-col :span="24">
        <el-col :span="6" class="mobile-product-tile">访问地址</el-col>
        <el-col :span="15" class="mobile-product-value">{{ instanceInfo.frontEndUrl }}</el-col>
      </el-col>
      <el-col :span="24">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="查看更多" name="1">
            <el-col :span="24">
              <el-col :span="6" class="mobile-product-tile">创建时间</el-col>
              <el-col :span="15" class="mobile-product-value">{{ instanceInfo.orderBeginTime }}</el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="6" class="mobile-product-tile">到期时间</el-col>
              <el-col :span="15" class="mobile-product-value">{{ instanceInfo.orderEndTime }}</el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="6" class="mobile-product-tile">初始账号</el-col>
              <el-col :span="15" class="mobile-product-value">{{ instanceInfo.instanceUserAccount }}</el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="6" class="mobile-product-tile">初始密码</el-col>
              <el-col :span="15" class="mobile-product-value">{{ instanceInfo.instanceUserPassword }}</el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="6" class="mobile-product-tile">服务状态</el-col>
              <el-col :span="15" class="mobile-product-value">
                <span v-if="orderStatus == 1">未开始</span>
                <span v-if="orderStatus == 2">服务中</span>
                <span v-if="orderStatus == 3">已结束</span>
              </el-col>
            </el-col>
          </el-collapse-item>
        </el-collapse>
      </el-col>
    </el-col>
    <!--    子订单信息-->
    <el-col>
    <el-form >
      <el-form-item>
        <el-col :span="24" :sm="0" class="mobile-instance" v-for="(item,index) in  tableData" :key="index">
          <el-col :span="24">
            <el-col :span="6" class="mobile-product-tile">子订单编号</el-col>
            <el-col :span="15" class="mobile-product-value">{{ item.orderNo }}</el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="6" class="mobile-product-tile">类型</el-col>
            <el-col :span="15" class="mobile-product-value">
              <span v-if="item.orderType == 1">新购</span>
              <span v-if="item.orderType == 2">续费</span>
              <span v-if="item.orderType == 3">升级</span>
              <span v-if="item.orderType == 4">扩容</span>
              <span v-if="item.orderType > 4">其他</span>
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="6" class="mobile-product-tile">规格</el-col>
            <el-col :span="15" class="mobile-product-value">{{ item.productSpecsName }}</el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="6" class="mobile-product-tile">金额</el-col>
            <el-col :span="15" class="mobile-product-value">{{ item.orderPayPrice }}</el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="6" class="mobile-product-tile">交易时间</el-col>
            <el-col :span="15" class="mobile-product-value">{{ item.orderPayTime }}</el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="6" class="mobile-product-tile">订单状态</el-col>
            <el-col :span="15" class="mobile-product-value">
              <span v-if="item.orderStatus == 1">待付款</span>
              <span v-if="item.orderStatus == 2">待开通</span>
              <span v-if="item.orderStatus == 3">已开通</span>
              <span v-if="item.orderStatus == 4">已销毁</span>
            </el-col>
          </el-col>
          <el-col>
            <div class="line-mobile"></div>
          </el-col>
          <el-col :span="24" class="down">
            <el-col :span="9" class="mobile-product-tile">操作</el-col>
            <el-col :span="5" v-show="item.orderStatus == 1" @click.native="payAgain1(item)" class="mobile-product-value-pay">立即支付</el-col>
            <el-col :span="5" v-show="item.orderStatus !== 1" style="margin: 1px"></el-col>
            <el-col :span="5" class="mobile-product-value-describe" @click.native="handleClick1(item)" >查看详情</el-col>
          </el-col>
        </el-col>
      </el-form-item>
    </el-form>
    </el-col>
    <el-col class="instance-main" :span="15" :xs="0">
      <el-col class="title">
        实例详情
      </el-col>
      <el-col class="instance-info" :span="11" :xs="0">
        <el-col class="img">
          <img :src="instanceInfo.saasProductLogo" style="width: 100%; height: 100%">
        </el-col>
        <el-col class="product-name">{{ instanceInfo.saasProductName }}</el-col>
        <el-col class="instance-btn-list" v-if="orderStatus == 2">
          <el-button class="btn" @click="goPage('/goodsRenewal', instanceInfo.instanceId, false)">续费</el-button>
          <el-button class="btn" @click="goPage('/goodsUpgrade', instanceInfo.instanceId, true)">扩容</el-button>
          <el-button class="btn" @click="goPage('/goodsUpgrade', instanceInfo.instanceId, false)">升级</el-button>
        </el-col>
      </el-col>
      <el-col class="instance-detail" :span="11" :xs="0">
        <el-col class="detail-list">
          <el-col class="item">产品ID：{{ instanceInfo.saasGoodsId }}</el-col>
          <el-col class="item">访问地址：{{ instanceInfo.frontEndUrl }}</el-col>
          <el-col class="item">创建时间：{{ instanceInfo.orderBeginTime }}</el-col>
          <el-col class="item">到期时间：{{ instanceInfo.orderEndTime }}</el-col>
          <el-col class="item">初始账号：{{ instanceInfo.instanceUserAccount }}</el-col>
          <el-col class="item">初始密码：{{ instanceInfo.instanceUserPassword }}</el-col>
          <el-col class="item">
            服务状态：
            <span v-if="orderStatus == 1">未开始</span>
            <span v-if="orderStatus == 2">服务中</span>
            <span v-if="orderStatus == 3">已结束</span>
          </el-col>
        </el-col>
      </el-col>
      <el-col class="order-list" :xs="0">
        <el-table :data="tableData" style="width: 100%" :header-cell-style="{ background: '#F7F7F7' }">
          <el-table-column prop="orderNo" label="子订单编号" width="200"></el-table-column>
          <el-table-column prop="orderType" label="类型" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.orderType == 1">新购</span>
              <span v-if="scope.row.orderType == 2">续费</span>
              <span v-if="scope.row.orderType == 3">升级</span>
              <span v-if="scope.row.orderType == 4">扩容</span>
              <span v-if="scope.row.orderType > 4">其他</span>
            </template>
          </el-table-column>
          <el-table-column prop="productSpecsName" label="规格" width="150"></el-table-column>
          <el-table-column prop="orderPayPrice" label="金额" width="100"></el-table-column>
          <el-table-column prop="orderPayTime" label="交易时间" width="200"></el-table-column>
          <el-table-column prop="orderStatus" label="订单状态" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.orderStatus == 1">待付款</span>
              <span v-if="scope.row.orderStatus == 2">待开通</span>
              <span v-if="scope.row.orderStatus == 3">已开通</span>
              <span v-if="scope.row.orderStatus == 4">已销毁</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" width="180">
            <template slot-scope="scope">
              <el-col :span="12" @click.native="handleClick(scope.row)" class="detail-btn">查看详情</el-col>
              <el-col :span="12" v-show="scope.row.orderStatus == 1" @click.native="payAgain(scope.row)"
                      class="detail-btn">立即支付
              </el-col>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            layout="prev, pager, next"
            :total="page.total"
            class="table-page"
            @current-change="handleCurrentChange"
            :current-page="page.pageNo"
        >
        </el-pagination>
      </el-col>
    </el-col>
    <!--订单详情弹窗-->
    <el-col  :sm="0" :span="24"  >
      <el-drawer :span="24"
                 title="我是标题"
                 :visible.sync="drawer"
                 :direction="direction"
                 :with-header="false"
                 size="80%">
        <el-col :span="24">
          <el-col :span="5" class="mobile-product-tile-b">子订单状态</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">产品名称</el-col>
          <el-col :span="15" class="mobile-product-value">{{ info.saasProductName }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">产品ID</el-col>
          <el-col :span="15" class="mobile-product-value">{{ info.saasGoodsId }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">初始账号</el-col>
          <el-col :span="15" class="mobile-product-value">{{ info.instanceUserAccount }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">订单号</el-col>
          <el-col :span="15" class="mobile-product-value">{{ info.orderNo }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">创建时间</el-col>
          <el-col :span="15" class="mobile-product-value">{{ info.orderBeginTime }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">到期时间</el-col>
          <el-col :span="15" class="mobile-product-value">{{ info.orderEndTime }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">服务状态</el-col>
          <el-col :span="15" class="mobile-product-value">
            <span v-if="orderStatus == 1">未开始</span>
            <span v-if="orderStatus == 2">服务中</span>
            <span v-if="orderStatus == 3">已结束</span>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">访问地址</el-col>
          <el-col :span="15" class="mobile-product-value">{{ info.frontEndUrl }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">规格名称</el-col>
          <el-col :span="15" class="mobile-product-value">{{ info.productSpecsName }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">初始密码</el-col>
          <el-col :span="15" class="mobile-product-value">{{ info.instanceUserPassword }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">订单类型</el-col>
          <el-col :span="15" class="mobile-product-value">
            <span>订单类型：</span>
            <span v-if="info.orderType == 1">新购</span>
            <span v-if="info.orderType == 2">续费</span>
            <span v-if="info.orderType == 3">升级</span>
            <span v-if="info.orderType == 4">扩容</span>
            <span v-if="info.orderType > 4">其他</span>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">支付时间</el-col>
          <el-col :span="15" class="mobile-product-value">{{ info.orderPayTime }}</el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">订单状态</el-col>
          <el-col :span="15" class="mobile-product-value">
            <span class="word43" v-if="info.orderStatus != 4">正常</span>
            <span class="word43" v-else>已销毁</span>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="7" class="mobile-product-tile">订单金额</el-col>
          <el-col :span="15" class="mobile-product-value">{{ info.orderPayPrice }}</el-col>
        </el-col>
        <el-col>
          <div class="line-mobile"></div>
        </el-col>
        <el-col :span="24">
          <el-col v-show="info.orderStatus == 3 && orderStatus != 1">
            <el-button  type="success" plain class="button">
              <span  @click="goPage('/goodsRenewal', instanceInfo.instanceId, false)">续费</span>
            </el-button>
            <el-button v-if="orderStatus != 3" class="button" type="success" plain>
              <span  @click="goPage('/goodsUpgrade', instanceInfo.instanceId, true)">扩容</span>
            </el-button>
            <el-button v-if="orderStatus != 3" class="button" type="success" plain>
              <span  @click="goPage('/goodsUpgrade', instanceInfo.instanceId, false)">升级</span>
            </el-button>
          </el-col>
          <el-col v-show="info.orderStatus == 1">
            <el-col class="mobile-count">
              总计
            </el-col>
            <el-col class="mobile-￥">
              ￥
            </el-col>
            <el-col class="mobile-pay-price">
              {{ info.orderPayPrice }}
            </el-col>
            <el-button @click="payAgain1(info)" class="button-pay" type="success" plain>
              <span >立即支付</span>
            </el-button>
          </el-col>
        </el-col>
      </el-drawer>
    </el-col>
    <div v-show="dialogVisible" ref="treeWrap" :xs="0">
      <div class="bd6 flex-col align-center">
        <div class="outer2 flex-col">
          <div class="bd7 flex-row justify-between">
            <span class="word29">订单详情</span>
            <div class="main8 flex-col" @click="dialogVisible=false; operation=false"></div>
          </div>
          <div class="bd8 flex-col"></div>
          <div class="order-content">
            <div class="order-info info-left">
              <div>
                <span>产品名称：</span>
                <span>{{ info.saasProductName }}</span>
              </div>
              <div>
                <span>产品ID：</span>
                <span>{{ info.saasGoodsId }}</span>
              </div>
              <div>
                <span>初始账号：</span>
                <span>{{ info.instanceUserAccount }}</span>
              </div>
              <div>
                <span>订单号：</span>
                <span>{{ info.orderNo }}</span>
              </div>
              <div>
                <span>创建时间：</span>
                <span>{{ info.orderBeginTime }}</span>
              </div>
              <div>
                <span>到期时间：</span>
                <span>{{ info.orderEndTime }}</span>
              </div>
              <div>
                <span>服务状态：</span>
                <span>
                                    <span v-if="orderStatus == 1">未开始</span>
                                    <span v-if="orderStatus == 2">服务中</span>
                                    <span v-if="orderStatus == 3">已结束</span>
                                </span>
              </div>
            </div>
            <div class="order-info info-right">
              <div>
                <span class="address">访问地址：{{ info.frontEndUrl }}</span>
              </div>
              <div>
                <span>规格名称：</span>
                <span>{{ info.productSpecsName }}</span>
              </div>
              <div>
                <span>初始密码：</span>
                <span>{{ info.instanceUserPassword }}</span>
              </div>
              <div>
                <span>订单类型：</span>
                <span v-if="info.orderType == 1">新购</span>
                <span v-if="info.orderType == 2">续费</span>
                <span v-if="info.orderType == 3">升级</span>
                <span v-if="info.orderType == 4">扩容</span>
                <span v-if="info.orderType > 4">其他</span>
              </div>
              <div>
                <span>支付时间：</span>
                <span>{{ info.orderPayTime }}</span>
              </div>
              <div>
                <span>订单状态：</span>
                <span class="word43" v-if="info.orderStatus != 4">正常</span>
                <span class="word43" v-else>已销毁</span>
              </div>
              <div>
                <span>订单金额：</span>
                <span>{{ info.orderPayPrice }}</span>
              </div>
            </div>
          </div>
          <div class="bd16 flex-row justify-between" v-show="info.orderStatus == 3 && orderStatus != 1">
            <el-button class="box17 flex-col" type="success" plain><span class="word46"
                                                                         @click="goPage('/goodsRenewal', instanceInfo.instanceId, false)">续费</span>
            </el-button>
            <el-button v-if="orderStatus != 3" class="box18 flex-col" type="success" plain><span class="word47"
                                                                                                 @click="goPage('/goodsUpgrade', instanceInfo.instanceId, true)">扩容</span>
            </el-button>
            <el-button v-if="orderStatus != 3" class="box19 flex-col" type="success" plain><span class="word48"
                                                                                                 @click="goPage('/goodsUpgrade', instanceInfo.instanceId, false)">升级</span>
            </el-button>

          </div>
          <div v-show="info.orderStatus == 1" class="bd16 flex-row justify-between"
               style="margin-left: 330px;width: 100px">
            <el-button @click="payAgain(info)" style="width: 90px" class="box19 flex-col"><span style="left: 350px"
                                                                                                class="word47">立即支付</span>
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <el-col class="product-pay" :xs="0">
      <el-dialog title="订单支付" :visible.sync="payDialog" class="pay-dialog" top="30vh" :close-on-click-modal="false"
                 @close="productPayClose">
          <el-col class="pay-type">
              <!--          <el-col class="pay-wechat active" :span="5">-->
              <!--            <i class="el-icon-wechat pay-icon"></i>-->
              <!--            <el-col :span="18" class="pay-title">微信扫码支付</el-col>-->
              <!--          </el-col>-->
              <el-col class="pay-ali " style="text-align: center">
                  <i class="el-icon-ali pay-icon"></i><span class="active" style="color: black">支付宝扫码支付</span>
              </el-col>
          </el-col>
          <el-col class="qr-code" id="qrcode">
              <img :src="qrCodeImg"/>
          </el-col>

        <el-col class="price">
          <span class="symbol">￥</span>
          <span class="amount">{{ price }}</span>
        </el-col>
      </el-dialog>
    </el-col>
  </el-row>


</template>

<script>

import member from "../common/MenberShipBar";
import orderApi from "../../api/orderApi";
import api from '@/api/payApi.js'
import QRCode from "qrcodejs2";

export default {
  name: "list",
  components: {
    member
  },
  data() {
    return {
      direction: 'btt',
      drawer: false,
      activeName: '1',
      operation: false,
      info: {
        productName: '文档系统SaaS标准版',
        url: 'www.edenedensoft.com.cn',
        productId: '12345678',
        adminUser: 'admin',
        specName: '50用户50G',
        adminPassword: '123456',
        orderId: '1234456778',
        orderType: '新增',
        createTime: '2022-02-22 22:22',
        payTime: '2022-02-22 22:22',
        expirationTime: '2023-02-22 22:22',
        orderStatus: '正常',
        serviceStatus: '服务中',
        orderAmount: '0.0'
      },
      instanceInfo: {},
      dialogVisible: false,
      tableData: [
        {
          'orderNo': 12313712937,
          'orderType': 1,
          'specsName': '10G50用户',
          'orderPayPrice': 5000,
          'orderPayTime': '2022-04-26 14:00:00'
        },
        {
          'orderNo': 1231371234,
          'orderType': 1,
          'specsName': '10G50用户',
          'orderPayPrice': 5000,
          'orderPayTime': '2022-04-26 14:00:00'
        }
      ],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      }
      , orderStatus: 1     //订单状态 1.未开始 2.服务中 3.已结束

      , payDialog: false,
      orderNo: '',
      qrCodeImg: '',
      wsUrl: api.socketUrl,
      websocket: null,
      price: 0,
    }
  },
  methods: {
    clickDown() {
      this.activeName = '1'
      console.log(this.activeName)
    },
    productPayClose() {
      this.orderNo = '';
      this.price = 0;
      this.qrCodeImg = '';
    },
    handleClick(row) {
      // if (row.orderNo === this.tableData[0].orderNo) {
      //     this.operation = true;
      // } else {
      //     this.operation = false;
      // }
      this.operation = true;
      this.dialogVisible = true
      this.info = row;
    },
    handleClick1(row) {
      this.info = row;
      this.drawer = true;
      // if (row.orderNo === this.tableData[0].orderNo) {
      //     this.operation = true;
      // } else {
      //     this.operation = false;
      // }
      this.operation = true;
      this.info = row;
    },
    payAgain(row) {
      this.orderNo = row.orderNo;
      this.price = row.orderOriginalPrice;
      this.initWebSocket();
      this.getQrCodeUrl(this.orderNo);
      this.payDialog = true;
    },
    // 移动端支付
    payAgain1(row) {
      this.orderNo = row.orderNo;
      this.price = row.orderOriginalPrice;
      this.initWebSocket();
      this.getQrCodeUrl1(this.orderNo);
    },
    listInstanceOrder(instanceId) {
      let obj = this;
      orderApi.getInstanceOrderList({
        instanceId: instanceId,
        pageNo: obj.page.pageNo,
        pageSize: obj.page.pageSize
      }).then(res => {
        if (res.data) {
          obj.tableData = res.data.records;
          let endDate = null;
          obj.tableData.forEach(item => {
            if (endDate == null && item.orderStatus == 3) {
              endDate = item.orderEndTime
            }
          })
          if (endDate == null) {
            obj.orderStatus = 1;
          } else {
            obj.orderStatus = Date.parse(endDate) >= new Date() ? 2 : 3
          }
          obj.total = res.data.total
        }
      });
    },
    getInstanceInfo(instanceId) {
      let obj = this;
      orderApi.getOrderDescription({
        instanceId: instanceId
      }).then(res => {
        if (res.data) {
          obj.instanceInfo = res.data;
        }
      })
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
    },
    goPage(name, instanceId, ifExpansion) {
      if (name) {
        sessionStorage.setItem("ifExpansion", ifExpansion);
        sessionStorage.setItem("instanceId", instanceId);
        this.$router.push({path: name});
      }
    },
    getQrCodeUrl(orderNo) {
      let obj = this;
      api.getQrCodeUrl({"orderNo": orderNo}).then((res) => {
        if (res.code != 200) {
          obj.price = 0;
          obj.orderNo = '';
          obj.payDialog = false;
          return obj.$message.error(res.message);
        }
        obj.qrCodeImg = res.data;
      })
    },
    // 移动端支付
    getQrCodeUrl1(orderNo) {
      let obj = this;
      api.getQrCodeUrl({"orderNo": orderNo, "type": "1"}).then((res) => {
        if (res.code != 200) {
          obj.price = 0;
          obj.orderNo = '';
          return obj.$message.error(res.message);
        }
        window.location.href = res.data;
      })
    },
    generatorQrCode(url) {
      this.qrcode = new QRCode('qrcode', {
        text: url,
        width: 166,
        height: 166
      })
    },
    // 使用websocket建立前后端通讯
    initWebSocket() {
      if (typeof WebSocket === 'undefined') {
        return null;
      }
      let wsUrl = this.wsUrl + this.orderNo;
      wsUrl = wsUrl.replace("https", "wss").replace("http", "ws");
      this.websocket = new WebSocket(wsUrl);
      this.websocket.onmessage = this.websocketOnMessage
      // this.websocket.onopen = this.websocketOnOpen
      this.websocket.onerror = this.websocketOnError
      this.websocket.onclose = this.websocketOnClose
    },
    websocketOnMessage(res) {
      // 数据接收
      const resData = JSON.parse(res.data);
      if (resData === 200) {
        this.$message.success("支付成功");
        this.orderNo = '';
        this.price = 0;
        this.qrCodeImg = '';
        //刷新页面
        this.$router.go(0);
      }
    },
    websocketOnOpen() {
      // 连接建立之后执行send发送数据
      // let data = {'orderNo': this.orderNo};
      // console.log(data);
      // this.websocketSend(JSON.stringify(data));
    },
    websocketOnError() {
      // 连接建立失败后重试
      this.initWebSocket();
    },
    websocketOnClose(e) {
      // 断开连接
      console.log("断开连接", e);
    },
    websocketSend(data) {
      console.log(this.websocket);
      this.websocket.send(data);
    },
  },
  mounted() {
    let self = this;
    document.addEventListener('mouseup', (e) => {
      let tree = this.$refs.treeWrap
      if (tree) {
        if (!tree.contains(e.target)) {
          self.dialogVisible = false;
          this.operation = false;
        }
      }
    })
    let instanceId = self.$route.query.instanceId;
    self.getInstanceInfo(instanceId)
    self.listInstanceOrder(instanceId);
  },
}
</script>

<style lang="less" scoped>
@media only screen and (max-width: 767px) {
  .page {
    height: 920px;
    background-color: #F5F5F5 !important;
  }
  /deep/ .el-button--success.is-plain[data-v-96626e4e]{
    background: #F56E65!important;
    border-color: #F56E65!important;
    color: #FFFFFF!important;
  }
}
.header-title {
  height: 21px;
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 18px;
  margin: 11px 0px 10px 18px;
}
.mobile-pay-price {
  margin: 23px 0px 0px 17px;
  width: 176px;
  height: 22px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
}
.mobile-￥ {
  margin: 28px 0px 0px 17px;
  width: 12px;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
}
.mobile-count {
  margin: 26px 0px 0px 17px;
  width: 24px;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
}
.button-pay {
  width: 112px;
  height: 36px;
  border-radius: 36px 36px 36px 36px;
  margin: 18px 0px 0px 18px;
  margin-right: 20px;
}
.button {
  width: 112px;
  height: 36px;
  border-radius: 36px 36px 36px 36px;
  margin: 18px 0px 0px 18px;
}
.down {
  margin-bottom: 20px;
}
.describe {
  //width: 72px;
  height: 28px;
  background: #3764D7;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 16px;;
}
.line-mobile {
  margin: 7px 32px 0px 16px;
  border-top: 1px solid #E6E6E6 ;
}

/deep/ .el-collapse-item__arrow {
  margin-left: 7px;
}

/deep/ .el-collapse-item__header {
  margin-left: 39%;
}
.mobile-product-tile-b {
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  line-height: 18px;
  margin: 12px 0px 0px 16px;
}
.mobile-product-tile {
  //width: 76px;
  height: 21px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin: 12px 0px 0px 16px;
}

.mobile-product-value {
  //width: 264px;
  height: 21px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  text-align: right;
  margin: 12px 16px 0px 0px;
}
.mobile-product-value-describe {
  color: #FFFFFF;
  border: 1px solid #F56E65;
  text-align: center;
  border-radius: 14px;
  height: 28px;
  line-height: 26px;
  background: #F56E65;
  margin: 12px 16px 0px 0px;
}
.mobile-product-value-pay {
  color: #FFFFFF;
  border: 1px solid #3764D7;
  text-align: center;
  border-radius: 14px;
  height: 28px;
  line-height: 26px;
  background: #3764D7;
  margin: 12px 16px 0px 0px;
}

.mobile-group-but {
  margin-top: 27px;
  margin-left: 12px;
}

.mobile-btn {
  font-weight: 400;
  font-size: 12px;
  width: 72px;
  line-height: 7px;
  height: 28px;
  background: #FFFFFF;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 1px solid #3764D7;
}

.mobile-product-name {
  width: 132px;
  height: 22px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  margin: 13px 0px 0px 14px;
}

.mobile-product_img {
  margin: 12px 0px 12px 16px;
  width: 78px;
  height: 78px;
}

.mobile-instance {
  width: 95.66667%;
  margin: 8px 8px 0px 8px;
  //height: 103px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
}

.order-info div {
  line-height: 43px;
}

.order-content {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0px 0px 40px;
  color: rgba(0, 0, 0, 1);
}

.info-right {
  width: 368px;
  //margin-left: 100px;
}

.address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.info-left {
  width: 368px;
}

.el-dialog, .el-pager li {
  background: none;
  -webkit-box-sizing: border-box;
}

.el-button {
  line-height: 0;
}

.el-button--success.is-plain:focus, .el-button--success.is-plain:hover {
  background: rgb(52, 100, 224);
  border-color: rgb(52, 100, 224);
  color: #FFF;

}

.el-button--success.is-plain:hover .txt5 {
  color: #FFFFFF;
}

.el-button--success.is-plain {
  color: #3764d7;
  border-color: #9dadd7;
  background: #ffffff;
}

@keyframes bd6 {
  0% {
    opacity: 0.1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.bd6 {
  animation: bd6 150ms;
  box-sizing: border-box;
  z-index: 212;
  height: 55%;
  border-radius: 11px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 10px 31px;
  display: flex;
  flex-direction: column;
  padding-top: 17px;
  width: 816px;
  position: absolute;
  left: 552px;
  align-items: center;
}

.outer2 {
  box-sizing: border-box;
  width: 100%;
  height: 392px;
  display: flex;
  flex-direction: column;
}

.bd7 {
  box-sizing: border-box;
  width: 729px;
  height: 23px;
  margin-left: 16px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.word29 {
  box-sizing: border-box;
  width: 72px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(55, 100, 215, 1);
  font-size: 18.00px;

  white-space: nowrap;
  line-height: 22.00px;
  text-align: left;
}

.main8 {
  box-sizing: border-box;
  width: 23px;
  height: 23px;
  background: url("../../img/order/closeIcon.svg");
  display: flex;
  border: none;
  flex-direction: column;
}

.bd8 {
  box-sizing: border-box;
  width: 752px;
  height: 1px;
  background: #e5e5e5;
  margin-top: 14px;
  display: flex;
  flex-direction: column;
}

.bd9 {
  box-sizing: border-box;
  width: 581px;
  height: 22px;
  flex-direction: row;
  display: flex;
  margin: 24px 0 0 16px;
}

.txt19 {
  box-sizing: border-box;
  width: 70px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14.00px;

  white-space: nowrap;
  line-height: 22.00px;
  text-align: left;
}

.word30 {
  box-sizing: border-box;
  width: 132px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14.00px;

  white-space: nowrap;
  line-height: 22.00px;
  text-align: left;
}

.word31 {
  box-sizing: border-box;
  width: 70px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14.00px;

  white-space: nowrap;
  line-height: 22.00px;
  text-align: left;
  margin-left: 134px;
}

.info11 {
  box-sizing: border-box;
  width: 175px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14.00px;

  white-space: nowrap;
  line-height: 22.00px;
  text-align: left;
}

.bd10 {
  box-sizing: border-box;
  width: 432px;
  height: 22px;
  flex-direction: row;
  display: flex;
  margin: 16px 0 0 30px;
}

.word32 {
  width: 56px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: right;
}

.txt20 {
  width: 73px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.word33 {
  width: 70px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: right;
  margin-left: 193px;
}

.txt21 {
  width: 40px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.bd11 {;
  box-sizing: border-box;
  width: 451px;
  height: 22px;
  flex-direction: row;
  display: flex;
  margin: 16px 0 0 16px;
}

.word34 {
  width: 70px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: right;
}

.word35 {
  width: 73px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.word36 {
  width: 70px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: right;
  margin-left: 193px;
}

.word37 {
  width: 45px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.bd12 {
  box-sizing: border-box;
  width: 420px;
  height: 22px;
  flex-direction: row;
  display: flex;
  margin: 16px 0 0 30px;
}

.word38 {
  width: 56px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: right;
}

.info12 {
  width: 80px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.word39 {
  width: 70px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: right;
  margin-left: 186px;
}

.info13 {
  width: 28px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.bd13 {
  box-sizing: border-box;
  width: 533px;
  height: 22px;
  flex-direction: row;
  display: flex;
  margin: 16px 0 0 16px;
}

.txt22 {
  width: 70px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: right;
}

.word40 {
  width: 127px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.info14 {
  width: 70px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: right;
  margin-left: 139px;
}

.word41 {
  width: 127px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.bd14 {
  box-sizing: border-box;
  width: 434px;
  height: 22px;
  flex-direction: row;
  display: flex;
  margin: 16px 0 0 16px;
}

.word42 {
  width: 70px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: right;
}

.txt23 {
  width: 127px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.txt24 {
  width: 70px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: right;
  margin-left: 139px;
}

.word43 {
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.bd15 {
  box-sizing: border-box;
  width: 435px;
  height: 22px;
  flex-direction: row;
  display: flex;
  margin: 16px 0 0 16px;
}

.word44 {
  width: 70px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: right;
}

.word45 {
  width: 42px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(36, 162, 18, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.word45red {
  width: 42px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: red;
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.txt25 {
  width: 70px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: right;
  margin-left: 224px;
}

.info15 {
  width: 29px;
  height: 22px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  white-space: nowrap;
  line-height: 22px;
  text-align: left;
}

.bd16 {
  box-sizing: border-box;
  width: 200px;
  height: 32px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin: 67px 0 0 306px;
}

.box17 {
  width: 56px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid rgba(180, 196, 237, 1);
}

.box18 {
  width: 56px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid rgba(180, 196, 237, 1);
}

.box19 {
  width: 56px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid rgba(180, 196, 237, 1);
}

.word46 {
  z-index: 219;
  position: absolute;
  left: 322px;
  top: 439px;
  width: 28px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  font-size: 14px;
  white-space: nowrap;
  line-height: 20px;
  text-align: left;
}

.word47 {
  z-index: 222;
  position: absolute;
  left: 394px;
  top: 439px;
  width: 28px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  font-size: 14px;
  white-space: nowrap;
  line-height: 20px;
  text-align: left;
}

.word48 {
  z-index: 225;
  position: absolute;
  left: 466px;
  top: 439px;
  width: 28px;
  height: 20px;
  display: block;
  overflow-wrap: break-word;
  font-size: 14px;
  white-space: nowrap;
  line-height: 20px;
  text-align: left;
}


.instance-main {
  margin: auto;
  height: auto;
  float: unset;

  .title {
    font-size: 32px;
    font-weight: 500;
    color: #3764D7;
    line-height: 45px;
    padding: 24px 0;
  }

  .instance-info {
    height: 307px;
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #C3C3C3;

    .img {
      //background: url("../../img/membership/product_cloud_doc.svg") no-repeat top center;
      width: 15vw;
      height: 172px;
      margin: 26px auto 0px auto;
      float: unset;
    }

    .product-name {
      font-size: 18px;
      font-weight: 500;
      color: #3764D7;
      line-height: 25px;
      text-align: center;
    }

    .instance-btn-list {
      text-align: center;
      margin-top: 28px;

      .btn {
        width: 56px;
        height: 32px;
        border-radius: 8px;
        border: 1px solid #B4C4ED;
        font-size: 14px;
        font-weight: 400;
        color: #3764D7;
        line-height: 20px;
      }

      /deep/ .el-button {
        padding: 6px 12px;
      }
    }
  }

  .instance-detail {
    height: 307px;
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #C3C3C3;
    margin-left: 3vw;

    .detail-list {
      padding-top: 1vw;
      padding-left: 2vw;

      .item:first-child {
        margin-top: 0;
      }

      .item {
        font-size: 1rem;
        font-weight: 400;
        color: #000000;
        line-height: 22px;
        margin-top: 1vw;
      overflow: hidden;
      text-overflow: ellipsis;
      }
    }
  }

  .order-list {
    margin-top: 44px;
    margin-bottom: 72px;

    .detail-btn:hover {
      cursor: pointer;
    }

    .detail-btn {
      font-size: 14px;
      font-weight: 500;
      color: #3764D7;
      line-height: 20px;
    }

    .table-page {
      text-align: center;
      margin-top: 20px;
    }
  }


}

.product-pay {

  .qr-code {
    margin-top: 23px;
      //margin-left: 370px;
      text-align: center;

    img {
      width: 160px;
      height: 160px;
    }
  }

  .price {
    text-align: center;
    margin-top: 16px;
    color: #E30000;
    line-height: 20px;

    .symbol {
      font-size: 14px;
      font-weight: 200;
    }

    .amount {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .pay-dialog {

    /deep/ .el-dialog__body {
      padding: 0 20px 30px 20px;
      height: 309px !important;
    }

    .pay-type {
      height: 50px;
      border-top: 1px solid #cbcbcb;
      padding: 16px 0 16px 0px;
      border-bottom: 1px solid #cbcbcb;

      .active {
        border-bottom: 3px solid #3764D7;
        padding-bottom: 8px;
      }

      .pay-ali {
          display: flex;
          justify-content: center;
      }

      .pay-wechat:hover, .pay-ali:hover {
        cursor: pointer;
      }

      .pay-wechat, .pay-ali {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        vertical-align: top;

        .el-icon-wechat {
          background: url("../../img/goods/weixinzhifu.svg") no-repeat top center;
          width: 22px;
          height: 19px;

        }

        .el-icon-ali {
          background: url("../../img/goods/zhifubao.svg") no-repeat top center;
          width: 20px;
          height: 20px;
        }

        .pay-icon {
          float: unset;
          display: inline-block;
        }

        .pay-title {
          float: unset;
          display: inline-block;
          vertical-align: top;
          margin-left: 9px;
        }
      }
    }
  }

}
</style>
