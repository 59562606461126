import {get,post} from "@/utils/axios"
//获取商品套餐详情
const goodsPay = p => get('api-common/pay/aliPay', p);

//调用开通接口
const OpenApplication = p => post('api-common/open/OpenApplication',p)

const generatorOrder = p => post('/api-order/order/generatorNewPurchaseOrder', p)

const getQrCodeUrl = p => get('/api-order/pay/aliPay', p)

const openApp = p => get('/api-order/pay/notify', p)

// const localSocketUrl = 'http://192.168.2.72:8762/api-order/websocket/';
//
const testSocketUrl = 'http://172.16.7.15:30672/api-order/websocket/';

//本地环境Socket地址
// const socketUrl = localSocketUrl;

//测试环境Socket地址
const socketUrl = testSocketUrl;


export default {
    goodsPay,
    OpenApplication,
    generatorOrder,
    getQrCodeUrl,
    openApp,
    socketUrl
}
